import React, { useRef, useState } from 'react';
import Tilt from "react-parallax-tilt";
import { motion, useScroll, useTransform } from "framer-motion"

import styles from './VideoCard.module.scss';

const VideoCard = ({ url, content }) => {

    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ['0 1', '1.33 1']
    })
    const scalePragess =  useTransform(scrollYProgress, [0, 1], [0.8, 1])
    const opacityPragess =  useTransform(scrollYProgress, [0, 1], [0.2, 1])
    const [isHover, setIsHover] = useState(false);

    const stopMovie = (e) => {
        e.preventDefault(); 
        const video = e.currentTarget;
        video.pause();
        video.currentTarget = 0;
        setIsHover(false);
      }
      
    const playMovie = (e) => {
        e.preventDefault(); 
        const video = e.currentTarget;
        video.play();
        setIsHover(true);
      }

    return (
        <motion.div 
            ref={ref} 
            style={{ 
                scale: scalePragess, 
                opacity: opacityPragess 
            }}
        >
            <Tilt 
                muted 
                glareEnable={true} 
                tiltMaxAngleX={10} 
                tiltMaxAngleY={10} 
                perspective={1200} 
                glareColor={"rgba(245, 243, 193, 0.1)"}
                className={styles.videoCardContainer}
                style={{background: isHover ? "#191919" : "transparent"}}
            >
                <div className={styles.contentContainer}>
                    <div className={styles.videoContainer}>
                        <video 
                            preload="metadata" 
                            onMouseOver={(e) => playMovie(e)}
                            onMouseOut={(e) => stopMovie(e)}
                            style={{width: "100%", height: "100%"}} 
                            muted 
                            id="url"
                            playsInline 
                            loading="lazy" 
                        >
                            <source src={url} type="video/mp4" />
                        </video>
                    </div>
                    <div className={styles.textContainer}>
                        <span>
                            {content}
                        </span>
                    </div>
                </div>
            </Tilt>
        </motion.div>
    )
}

export default VideoCard;