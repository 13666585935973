import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SANLayout from './SANLayout';
import HomePage from './pages/homePage/HomePage';
import PricingPage from './pages/pricingPage/PricingPage';
import BlogPage from './pages/blogPage/BlogPage';
import ReactGA from 'react-ga4';

const AppRoutes = () => {

  useEffect(() => {
    // Google Analytics'i başlatmak için izleme kimliğini kullan
    ReactGA.initialize("G-WYGF92NGDK");
    // İlk sayfa görüntülemesini izlemek için
    ReactGA.send("pageview");
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [window.location.pathname]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<SANLayout/>}>
          <Route path="/" element={<HomePage/>} />
          <Route path="/pricing" element={<PricingPage/>} />
          {/* <Route path="/blog" element={<BlogPage/>} /> */}
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRoutes;