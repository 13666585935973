import React from 'react';
import BackgroundVideo from '../../components/backgroundVideo/BackgroundVideo';
import { motion } from "framer-motion"
import VideoCard from '../../components/videoCard/VideoCard';
import video1 from '../../static/videos/output_3.webm'
import video2 from '../../static/videos/output_11.webm'
import video3 from '../../static/videos/output_12.webm'
import video4 from '../../static/videos/output_16.webm'
import video5 from '../../static/videos/output_20.webm'
import video6 from '../../static/videos/output_27.webm'
import video7 from '../../static/videos/output_space_1.webm'
import video8 from '../../static/videos/output_space_3.webm'
import { Link } from 'react-router-dom';

import styles from './HomePage.module.scss';

const HomePage = () => {

    const videoDatas = [
        { videoUrl: video1, content: 'A mystical waterfall hidden deep in an enchanted forest, with glowing flowers around it.' },
        { videoUrl: video2, content: 'A futuristic train speeding through a desert landspace, leaving a trail of dust.' },
        { videoUrl: video3, content: 'A surreal scene where planets orbit a tree in the middle of an empty desert.' },
        { videoUrl: video4, content: 'A mysterious figure walking through a dense fog, with glowing eyes following their path.' },
        { videoUrl: video5, content: 'A steampunk airship flying through a sky filled with gears and clockwork machinery.' },
        { videoUrl: video6, content: 'A serene mountain lake reflecting the stars, with fireflies glowing above the water.' },
        { videoUrl: video7, content: 'A massive space station orbiting a distant planet, with spaceships docking and departing.' },
        { videoUrl: video8, content: 'An astronaut walking on the surface of Mars, with a bright Earth visible in the distance.' },
    ]

    return (
        <div  className={styles.homePageContainer}>
            <div className={styles.textToVideoContainer}>
                <BackgroundVideo />
                <div className={styles.backgroundContainer}></div>
                <div className={styles.textToVideoMainContainer}>
                    <motion.h2
                        initial={{ x: -160, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 0.6 }}
                    >
                        AI VIDEO GENERATOR
                    </motion.h2>
                    <motion.p
                        initial={{ x: 160, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 0.6 }}
                    >
                        Create and edit breathtaking videos effortlessly using our cutting-edge AI tools, 
                        tailored to realize your creative vision.
                    </motion.p>
                    <Link to={"https://docs.google.com/forms/d/e/1FAIpQLSdWQV7DnMtNGEpMynVVOKXso0RzSfdjYwZmXhiPcOEaIzpUOg/viewform"} target='_blank' className={styles.joinButtonContainer}>
                        <span>Join Waitlist</span>
                    </Link>
                </div>
            </div>
            
            <div className={styles.bgOpacity}></div>

            <div className={styles.titleContainer}>
                <div className={styles.titleMainContainer}>
                    <h2>Trusted by 90k+ users</h2>
                    <div className={styles.line}></div>
                    <span>
                        Users All Over the World Trust Kubrix for AI-Powered Solutions. Empowering Success, Globally.
                    </span>
                </div>
            </div>
            <div className={styles.videosContainer}>
                <div className={styles.videosMainContainer}>
                    {
                        videoDatas.map((val, idx) => (
                            <VideoCard key={idx} url={val.videoUrl} content={val.content} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default HomePage