import React from 'react';
import logo from '../../static/logo.png';
import { Link, useNavigate } from 'react-router-dom';

import styles from './Header.module.scss';

const Header = () => {

    const navigate = useNavigate();

    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerMainContainer}>
                <div onClick={() => navigate('/')} className={styles.leftContainer}>
                    <img style={{ width: "100%", height: "100%" }} src={logo} alt="" />
                </div>
                <div className={styles.midContainer}>
                    <Link className={styles.menuItem} to={"/pricing"}>
                        Pricing
                    </Link>
                    {/* <Link className={styles.menuItem} to={"/blog"}>
                        Blog
                    </Link> */}
                </div>
                <div className={styles.rightContainer}>
                    <Link to={"https://docs.google.com/forms/d/e/1FAIpQLSdWQV7DnMtNGEpMynVVOKXso0RzSfdjYwZmXhiPcOEaIzpUOg/viewform"} target='_blank' className={styles.joinButtonContainer}>
                        <span>Join Waitlist</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Header